.circle-icon,
.map-tooltip,
.map-popup > div {
  font-size: 12px;
  background: rgba(225, 225, 225, 0.7);
  border-radius: 50%;
  height: '100%';
  width: '100%';
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 4px 9px 4px 0px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  z-index: 9999;
  border: 2px solid white;
}

.poi-tooltip {
  font-size: 12px;
  background: rgba(249, 249, 140, 0.532);
  /* border-radius: 50%; */
  height: '100%';
  width: '100%';
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 4px 9px 4px 0px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  z-index: 9999;
  border: 2px solid white;
}

.leaflet-popup-close-button > span {
  color: red;
}

.map-popup > div {
  border-radius: 10px;
  padding: 8px;
}

.map-tooltip {
  font-weight: bold;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 4px 9px 4px 0px rgba(0, 0, 0, 0.2);
}

.leaflet-popup-tip-container {
  visibility: hidden;
}

.circle-icon-secondary {
  background: transparent;
  border-radius: 50%;
  height: '100%';
  width: '100%';
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.clickable {
  cursor: pointer;
}
